import repository from './repository'

const resource = 'feeding-program-details'

export default {
  index (feedingProgramId) {
    return repository.get(`${resource}/${feedingProgramId}`)
  },
  delete (id) {
    return repository.delete(`${resource}/${id}/delete`)
  },
  store (data) {
    return repository.post(resource, data)
  },
  update (id, data) {
    return repository.put(`${resource}/${id}/edit`, data)
  },
  validate (data) {
    return repository.post(`${resource}/validate`, data)
  },
}
