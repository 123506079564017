<template lang="pug">
  div
    base-snackbar(
      :errors.sync="feedingProgramDetailPostErrors"
    )
    v-row
      v-col(cols="12")
        v-text-field(
          label="Day"
          type="number"
          v-model="day"
          :disabled="editMode"
          :error-messages="errors.day"
        )
      v-col(cols="12")
        v-text-field(
          label="Feed Per Day (Kg)"
          v-model="feedPerDayKg"
          type="number"
          :error-messages="errors.feedPerDayKg"
        )
    v-btn.mr-2.mt-2(
      color="primary"
      dark
      dense
      small
      :loading="feedingProgramDetailPosting"
      @click="sendFeedingProgramDetailRequest"
    )
      span Save

    v-btn.mt-2(
      v-if="editMode"
      color="error"
      dark
      dense
      small
      @click="cancelEditMode"
    )
      span Cancel
</template>
<script>
import VueRequest from '@/libs/classes/VueRequest.class'
import feedingProgramDetailRepository from '@/repositories/feeding-program-detail.repository'
import { postVars } from '@/libs/api-helper.extra'

const inputVars = () => ({
  id: null,
  day: null,
  feedPerDayKg: null,
})

const postVarNames = baseName => ({
  loading: baseName + 'Posting',
  error: baseName + 'PostErrors',
})

export default {
  name: 'FeedingProgramDetailCreate',
  props: {
    defaultFieldValues: {
      type: Object,
      default: () => ({ }),
    },
    lastDay: {
      type: Number,
      default: null,
    },
  },
  data: () => ({
    ...inputVars(),
    ...postVars('feeding-program-detail'),
  }),
  computed: {
    errors () {
      return this.$objectChangeKeyCase(this.feedingProgramDetailPostErrors)
    },
    editMode () {
      return !this.$objectEmpty(this.defaultFieldValues)
    },
    feedingProgramId () {
      return Number(this.$route.params.feedingProgramId)
    },
  },
  watch: {
    defaultFieldValues (item) {
      this.populateFields(item)
    },
    lastDay () {
      this.setDayDefault()
    },
  },
  methods: {
    setDayDefault () {
      this.day = this.lastDay + 1
    },
    sendFeedingProgramDetailRequest () {
      if (this.feedinProgramDetailPosting) return
      if (this.editMode) {
        this.updateFeedingProgramDetail()
        return
      }
      this.storeFeedingProgramDetail()
    },
    updateFeedingProgramDetail () {
      const requestVarNames = postVarNames('feedingProgramDetail')
      const handler = new VueRequest(this, requestVarNames, { hasList: false })
      const repository = feedingProgramDetailRepository.update
      const { id, data } = this.getInputFieldValues()
      handler.execute(repository, [id, data], this.updateFeedingProgramDetailStatusOk)
    },
    storeFeedingProgramDetail () {
      const requestVarNames = postVarNames('feedingProgramDetail')
      const handler = new VueRequest(this, requestVarNames, { hasList: false })
      const repository = feedingProgramDetailRepository.store
      const { data } = this.getInputFieldValues()
      handler.execute(repository, [data], this.storeFeedingProgramDetailStatusOk)
    },
    getInputFieldValues () {
      const included = ['feedingProgramId']
      const data = Object.keys(inputVars())
        .concat(included)
        .filter(key => key !== 'id')
        .reduce((result, key) => {
          const key2 = key.camelToSnakeCase()
          result[key2] = this[key]
          return result
        }, {})
      const { id } = this
      return { id, data }
    },
    populateFields (item) {
      Object.keys(item)
        .forEach(key => {
          const key2 = key.snakeToCamelCase()
          if (item[key] !== this[key2]) this[key2] = item[key]
        })
    },
    updateFeedingProgramDetailStatusOk () {
      this.clearDefaultFieldValues()
      this.clearInputFields()
    },
    storeFeedingProgramDetailStatusOk () {
      this.clearInputFields()
    },
    cancelEditMode () {
      this.clearDefaultFieldValues()
      this.clearInputFields()
    },
    clearDefaultFieldValues () {
      this.$emit('update:defaultFieldValues', {})
    },
    clearInputFields () {
      const defaults = inputVars()
      const excempt = ['feedingProgramId', 'day', 'feedPerDayKg']
      Object.keys(defaults).forEach(key => {
        if (!excempt.includes(key)) this[key] = defaults[key]
      })
      this.setDayDefault()
    },
  },
}
</script>